/* Dark Mode */
.dark .kernel-form {
  background: #121212 !important;
  color: #f6f6f6 !important;
  font-family: "Inter";
  overflow-x: hidden;
}

.ql-editor span {
  white-space: pre-wrap !important;
}

.dark .light-mode a[class^="iframe"] *,
.dark .light-mode a.embed-iframely * {
  background: transparent !important;
  color: white !important;
}

.dark .ic_action {
  display: inline-flex;
  border-radius: 15px;
  border: 1px solid;
  width: 128px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  margin-top: 12px;
  cursor: pointer;
}

.dark .ic_action.active {
  background-color: white;
  color: black;
}

.dark .ql-container {
  font-size: 20px;
  color: white;
}

.dark .quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  color: #bbbbbb;
}

.dark .ql-snow.ql-toolbar.ql-header button {
  background-color: aliceblue;
}

.dark .title_kernel {
  background: unset;
  color: white;
  width: 90%;
  border: unset;
  margin: 12px 15px 0px 15px;
  font-size: 28px;
  height: 50px;
}

.dark .title_kernel::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
  font-weight: 700;
}

.dark .title_kernel:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  font-weight: 700;
}

.dark .title_kernel::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
  font-weight: 700;
}

.dark .custom-btn-toolbar {
  border: 1px solid #fff !important;
  width: auto !important;
  border-radius: 8px;
  height: 35px !important;
  margin-right: 12px;
  color: white;
}

.dark .custom-btn-toolbar.ql-active .text-toolbar {
  color: #2c3030 !important;
}

.dark .custom-btn-toolbar .ql-stroke,
.dark .custom-btn-toolbar .ql-fill,
.dark .custom-btn-toolbar.ql-active .ql-thin {
  stroke: white;
}

.dark .custom-btn-toolbar.ql-active {
  background: white !important;
}

.dark .custom-btn-toolbar.ql-active .ql-stroke,
.dark .custom-btn-toolbar.ql-active .ql-fill,
.dark .custom-btn-toolbar.ql-active .ql-thin {
  stroke: #2c3030 !important;
}

.dark .ql-snow.ql-toolbar button:hover,
.dark .ql-snow .ql-toolbar button:hover,
.dark .ql-snow.ql-toolbar button:focus,
.dark .ql-snow .ql-toolbar button:focus,
.dark .ql-snow.ql-toolbar button.ql-active,
.dark .ql-snow .ql-toolbar button.ql-active,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #2c3030 !important;
  background: white !important;
}

.dark .custom-btn-toolbar:hover .ql-stroke,
.dark .custom-btn-toolbar:hover .ql-fill,
.dark .custom-btn-toolbar:hover.ql-active .ql-thin {
  stroke: #2c3030 !important;
}

.dark .custom-btn-toolbar.ql-active:hover .ql-stroke,
.dark .custom-btn-toolbar.ql-active:hover .ql-fill,
.dark .custom-btn-toolbar.ql-active:hover .ql-thin {
  stroke: #2c3030 !important;
}

.dark .overlay {
  position: absolute;
  background-image: linear-gradient(rgba(44, 48, 48, 0), rgba(44, 48, 48, 1));
  width: 100%;
  bottom: 0;
  z-index: 2;
  height: 60px;
  border-radius: 8px;
}

.kernel-form .author-edit div:first-of-type {
  justify-content: left !important;
  width: 100% !important;
  max-width: unset !important;
}

.dark .kernel-form a.iframe-Movie div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
}

.dark .kernel-form div.iframe-video div:nth-of-type(1) {
  height: 180px !important;
}

.dark .kernel-form a.iframe-Location div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
}

.dark .kernel-form a.iframe-Book div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
}

.dark .breadcrumb-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding-left: 10px;
  color: #f3bc01;
  cursor: pointer;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.dark .dark\:bg-inherit {
  background-color: inherit !important;
}

.dark .loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #f3bc01;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  bottom: 0;
  right: 0;
  position: absolute;
  top: 45%;
  left: 45%;
}

.dark .kernel-form a.embed-iframely div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
  color: white;
}

/* Light Mode */
.light .kernel-form {
  background: #ffffff !important;
  color: black !important;
  font-family: "Inter";
  overflow-x: hidden;
}

.light .light-mode a[class^="iframe"] *,
.light .light-mode a.embed-iframely * {
  background: transparent !important;
  color: rgb(0, 0, 0) !important;
}

.container-fluid {
  padding-right: unset !important;
  padding-left: unset !important;
}

.container_iframe {
  display: inline-grid;
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 3;
}

@media (max-width: 768px) {
  .container_iframe {
    position: unset;
    display: flex;
  }
}

.container-exit {
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: 100px;
  right: 20px;
  cursor: pointer;
  z-index: 3;
}

.container-save {
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: 25px;
  right: 20px;
  cursor: pointer;
  z-index: 3;
}

.text_iframe {
  font-weight: 600;
  font-size: 16px;
}

img.icon_exit {
  width: 100%;
}

img.icon_exit.disabled {
  filter: brightness(0.25);
}

.light .ic_action {
  display: inline-flex;
  border-radius: 15px;
  border: 1px solid black;
  width: 128px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  margin-top: 12px;
  cursor: pointer;
  background-color: white;
  color: black;
}

.light .ic_action.active {
  background-color: black;
  color: white;
  border: 1px solid white;
}

.active .icon_iframe {
  filter: hue-rotate(180deg);
}

.ic_action.img_location {
  width: 150px;
}

.ic_action.img_image {
  width: 170px;
}

.ic_action.img_podcast {
  width: 152px;
}

.ic_action.img_movie {
  width: 166px;
}

.ic_action.img_youtube {
  width: 156px;
}

img.icon_iframe {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  margin-left: 18px;
}

.ql-container.ql-snow {
  border: none;
}

.light .ql-container {
  font-size: 20px;
  color: black;
}

.light .quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  color: #bbbbbb;
}

.ql-toolbar.ql-snow {
  border: none;
}

.light .ql-snow.ql-toolbar.ql-header button {
  background-color: black;
}

.editor {
  height: 100%;
  border: none !important;
}

.light .title_kernel {
  background: unset;
  color: black;
  width: 90%;
  border: unset;
  margin: 12px 15px 0px 15px;
  font-size: 28px;
  height: 50px;
}

.title_kernel:focus-visible {
  outline: unset;
}

.light .title_kernel::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
  font-weight: 700;
}

.light .title_kernel:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-weight: 700;
}

.light .title_kernel::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
  font-weight: 700;
}

.validation-message {
  color: red;
  margin: 0px 15px;
  font-size: 14px;
}

.validation-message-input {
  color: red;
  font-size: 14px;
  text-align: left;
}

.light .custom-btn-toolbar {
  border: 1px solid black !important;
  width: auto !important;
  border-radius: 8px;
  height: 35px !important;
  margin-right: 12px;
  color: black;
}

.custom-btn-toolbar .text-toolbar {
  font-size: 18px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.light .custom-btn-toolbar.ql-active .text-toolbar {
  color: white !important;
}

.light .custom-btn-toolbar .ql-stroke,
.light .custom-btn-toolbar .ql-fill,
.light .custom-btn-toolbar.ql-active .ql-thin {
  stroke: black;
}

.custom-btn-toolbar svg {
  float: left;
  height: 90% !important;
}

.light .custom-btn-toolbar.ql-active {
  background: black !important;
}

.custom-btn-toolbar.ql-active .ql-stroke,
.custom-btn-toolbar.ql-active .ql-fill,
.custom-btn-toolbar.ql-active .ql-thin {
  stroke: white !important;
}

.light .ql-snow.ql-toolbar button:hover,
.light .ql-snow .ql-toolbar button:hover,
.light .ql-snow.ql-toolbar button:focus,
.light .ql-snow .ql-toolbar button:focus,
.light .ql-snow.ql-toolbar button.ql-active,
.light .ql-snow .ql-toolbar button.ql-active,
.light .ql-snow.ql-toolbar .ql-picker-label:hover,
.light .ql-snow .ql-toolbar .ql-picker-label:hover,
.light .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.light .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.light .ql-snow.ql-toolbar .ql-picker-item:hover,
.light .ql-snow .ql-toolbar .ql-picker-item:hover,
.light .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.light .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: white !important;
  background: black !important;
}

.light .custom-btn-toolbar:hover .ql-stroke,
.light .custom-btn-toolbar:hover .ql-fill,
.light .custom-btn-toolbar:hover.ql-active .ql-thin {
  stroke: white !important;
}

.light .custom-btn-toolbar.ql-active:hover .ql-stroke,
.light .custom-btn-toolbar.ql-active:hover .ql-fill,
.light .custom-btn-toolbar.ql-active:hover .ql-thin {
  stroke: white !important;
}

.ql-editor {
  white-space: unset;
  font-size: 16px;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 16px;
}

.react-quill-custom {
  scroll-behavior: auto;
}

.light .overlay {
  position: absolute;
  background-image: linear-gradient(rgba(44, 48, 48, 0), rgb(255 255 255));
  width: 100%;
  bottom: 0;
  z-index: 2;
  height: 60px;
  border-radius: 8px;
}

div#toolbar {
  position: absolute;
  bottom: 20px;
  z-index: 3;
}

div.text-custom {
  max-width: 700px;
}

a span[contenteditable] {
  display: flex;
}

.Reader-see.Reader-see-youtube {
  display: none !important;
}

.kernel-form .author-edit.author-edit-youtube {
  display: flex !important;
}

.view-title-youtube {
  height: 60px;
}

.modal-custom-kernel .location-search .image-search {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-search img {
  width: 48px;
  height: 48px;
}

.modal-custom-kernel .youtube-search .image-search {
  margin-right: 5px;
  margin-left: 8px;
}

.modal-custom-kernel .location-search .description {
  white-space: nowrap;
  height: unset;
}

.modal-custom-kernel .book-search img {
  object-fit: cover;
  width: 120px;
}

/* add new */

.kernel-form .author-options {
  display: none !important;
}

.kernel-form .author-edit {
  width: 100% !important;
}

.kernel-form .author-edit div:nth-of-type(2) {
  display: none;
}

.light .kernel-form .author-edit div:first-of-type {
  justify-content: left !important;
  width: 100% !important;
  max-width: unset !important;
  color: black;
}

.kernel-form .view-title-music {
  width: 100%;
}

.kernel-form a.iframe-Movie div:nth-of-type(1) .author-edit {
  width: 100%;
  display: none !important;
}

.kernel-form a.iframe-Movie div:nth-of-type(1) div:nth-of-type(2) {
  display: none !important;
}

.light .kernel-form a.iframe-Movie div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
  color: black;
}

.kernel-form a.iframe-Movie .overview {
  max-width: 400px;
}

/* iframe embeded */

.kernel-form a.embed-iframely div:nth-of-type(1) .author-edit {
  width: 100%;
  display: none !important;
}

.light .kernel-form a.embed-iframely div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
  color: black;
}

.kernel-form a.embed-iframely .overview {
  max-width: 400px;
}

.kernel-form img.author-edit.icon-delete {
  display: none;
}

.kernel-form img.author-edit.icon-delete-video {
  display: none;
}

.light .kernel-form div.iframe-video div:nth-of-type(1) {
  height: 180px !important;
  color: black !important;
}

.kernel-form div.iframe-video video {
  width: 100%;
  height: 180px !important;
}
/*  */

.kernel-form a.iframe-Location div:nth-of-type(1) .author-edit {
  width: 100%;
  display: none !important;
}

.light .kernel-form a.iframe-Location div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
  color: black !important;
}

.kernel-form a.iframe-Location .overview {
  max-width: 400px;
}

/*  */

.kernel-form a.iframe-Book div:nth-of-type(1) .author-edit {
  width: 100%;
  display: none !important;
}

.light .kernel-form a.iframe-Book div:nth-of-type(1) {
  justify-content: left !important;
  width: 100% !important;
  color: black !important;
}

.kernel-form a.iframe-Book .overview {
  max-width: 400px;
}

.kernel-form a.iframe-Book div:nth-of-type(1) div:nth-of-type(2) {
  /* display: none !important; */
  background: unset !important;
}

.react-quill-custom {
  max-width: 700px;
}

.breadcrumb-kernel {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.light .breadcrumb-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding-left: 10px;
  color: rgb(39, 91, 87);
  cursor: pointer;
}

.breadcrumb-header {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding-left: 15px;
}

.ql-tooltip {
  display: none;
}

.light ::-webkit-scrollbar-thumb {
  background-color: #1a1f21;
}

.light ::-webkit-scrollbar-thumb {
  background-color: #1a1f21;
}

.light ::-webkit-scrollbar-thumb {
  background-color: #1a1f21;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.light .loader {
  border: 3px solid #0d0c0c;
  border-radius: 50%;
  border-top: 3px solid #f3bc01;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  bottom: 0;
  right: 0;
  position: absolute;
  top: 45%;
  left: 45%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container-data-search {
  min-height: 300px;
  position: relative;
}

#react-quill-editor a {
  text-decoration: underline;
}

.ql-editor .customLink {
  display: unset !important;
}
