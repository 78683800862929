.content-container {
  font-size: 16px;
}

.content-container .author-edit {
  display: none !important;
}

.content-container a {
  color: #7b61ff !important;
  text-decoration: underline;
}

.content-container a[class^="iframe"] > div:first-child,
.content-container a.embed-iframely > div:first-child {
  background: #121212;
}

.content-container a[class^="iframe"] > div:first-child,
.content-container a.embed-iframely > div:first-child {
  background: transparent !important;
  color: #121212 !important;
}

.content-container .light-mode a[class^="iframe"] *,
.content-container .light-mode a.embed-iframely * {
  background: transparent !important;
  color: #121212 !important;
}
iframe.iframe-Reader.iframe-youtube {
  max-width: 600px !important;
  width: 100% !important;
  margin: auto !important;
  min-height: 300px !important;
  border-radius: 8px !important;
}

.iframe-Image > div,
.iframe-Image .Reader-see.image-custom {
  height: auto !important;
  width: 300px !important;
}

.content-container div.Reader-see {
  display: block !important;
}

.content-container iframe.iframe-Reader {
  border: none;
}

.edit-embed a {
  text-decoration: none;
}
