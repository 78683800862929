.modal-custom-kernel {
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(246, 246, 246, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  left: 50%;
  min-height: 400px;
  max-width: 700px;
  padding: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-custom-kernel.Search-link {
  min-height: 350px;
}

.popup {
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-custom-kernel .app-store {
  margin: 20px;
}

.modal-custom-kernel.crowd {
  padding-bottom: 80px;
  /* background-image: url(../assets/crowd.svg); */
  background-position: bottom;
  background-repeat: no-repeat;
}

.modal-custom-kernel .logo-container {
  margin: 0 auto;
}

.modal-custom-kernel .close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  color: rgba(44, 48, 48, 1);
}

.dark-mode .modal-custom-kernel .close {
  color: rgba(44, 48, 48, 1);
}

.modal-custom-kernel .close:hover {
  cursor: pointer;
}

@media (max-width: 650px) {
  .modal-custom-kernel {
    width: 90%;
  }
}

.modal-custom-kernel .title-modal {
  text-align: start;
  font-weight: 700;
  font-size: 24px;
  color: rgba(44, 48, 48, 1);
}

.modal-custom-kernel input.search-modal {
  width: 400px;
  border: 1px solid #164f4b;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 5px;
  box-shadow: 0px 16px 32px rgba(44, 48, 48, 0.1),
    0px 2px 2px rgba(44, 48, 48, 0.05);
  color: rgba(44, 48, 48, 1);
}

.modal-custom-kernel .title-link {
  color: #525656;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 25px;
  padding-bottom: 6px;
  text-align: left;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.modal-custom-kernel input.search-modal:focus-visible {
  outline: unset;
}

.modal-custom-kernel .container-search {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #dedede;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 12px 0px;
}

.modal-custom-kernel .item-search:nth-child(even) {
  background: #f6f6f6;
}

.modal-custom-kernel .item-search {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.modal-custom-kernel .image-search {
  width: 120px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}

.modal-custom-kernel .overview-modal {
  justify-content: center;
  align-items: center;
  height: 90px;
  display: flex;
  flex-direction: column;
}

.modal-custom-kernel .title {
  color: rgba(44, 48, 48, 1);
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 250px;
  padding-left: 10px;
  font-size: 16px;
  text-align: left;
  padding-bottom: 3px;
}

.modal-custom-kernel .description {
  color: rgba(82, 86, 86, 1);
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: revert;
  width: 250px;
  padding-left: 10px;
  text-align: left;
  height: 60px;
  font-size: 13px;
  font-family: "Inter";
}

.modal-custom-kernel .TvMovie-search .releaseDate {
  color: rgba(82, 86, 86, 1);
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 250px;
  padding-left: 10px;
  font-size: 16px;
  text-align: left;
  padding-bottom: 3px;
}

.modal-custom-kernel .TvMovie-search .overView {
  color: rgba(82, 86, 86, 1);
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: revert;
  width: 250px;
  padding-left: 10px;
  text-align: left;
  height: 50px;
  font-size: 13px;
  font-family: "Inter";
}

/* css popup exit */

.modal-custom-kernel .title-exit {
  font-weight: 600;
  font-size: 16px;
}

.modal-custom-kernel .Content-exit {
  justify-content: center;
  align-items: center;
  padding-top: 34px;
  color: rgba(44, 48, 48, 1);
}

.modal-custom-kernel .footer-exit {
  display: flex;
  justify-content: right;
  height: 45px;
  margin-top: 30px;
}

.modal-custom-kernel.Exit {
  min-height: unset;
}

.modal-custom-kernel .btn-cancel {
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0px 8px;
  padding: 8px 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(44, 48, 48, 1);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.modal-custom-kernel .btn-exit {
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  background: rgba(243, 70, 1, 1);
}
.modal-custom-kernel .btn-exit a {
  color: #fff;
  font-family: Inter;
  text-decoration: unset;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.modal-custom-kernel .btn-add {
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  background: rgba(22, 79, 75, 1);
}
