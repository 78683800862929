.modal-custom-kernel.Alert-subscriber {
    text-align: center;
    background: #ffffff;
    border: 1px solid rgba(246, 246, 246, 1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    left: 50%;
    min-height: unset;
    max-width: 700px;
    padding: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.Alert-subscriber input.search-modal {
    width: 400px;
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    padding: 18px;
    box-shadow: 0px 16px 32px rgb(44 48 48 / 10%), 0px 2px 2px rgb(44 48 48 / 5%);
    margin-bottom: 0;
}

.fill-dark-gray-alert {
    fill: #8B8B8B;
}

.view-alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 26px;
}

img.icon-alert {
    width: 40px;
    height: 24px;
    margin-right: 16px;
    cursor: pointer;
}

.user-alert {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    padding: 15px;
}

.wrapInfoItem {
    display: flex;
    flex-direction: row;
}

img.avt-alert {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 25px;
}

.name-user {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding-left: 10px;
}

img.icon-select {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.list-user-alert {
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px 0px;
}

.Alert-subscriber input.search-modal.disabled {
    background: #DEDEDE;
}