* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: auto;
}

:root {
  --toolbar-height: 56px;
}

.with-sidebar {
  column-gap: 2rem;
  overflow-y: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
}

.with-sidebar,
.with-sidebar > div {
  max-height: calc(100vh - var(--toolbar-height));
}

.explore-sidebar {
  overflow-y: hidden;
  margin: 0 auto;
  width: 100%;
}

.explore-sidebar,
.explore-sidebar > div {
  max-height: calc(100vh - var(--toolbar-height));
}

.thin-scrollbar::-webkit-scrollbar-track,
.thin-scrollbar::-webkit-scrollbar {
  background: transparent;
  border-radius: 8px;
  width: 4px;
}
*:hover.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
}

.layout {
  display: grid;
  grid-template-rows: var(--toolbar-height) auto;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  min-width: 100%;
}

.gradient {
  background: linear-gradient(247.66deg, #000000 0%, #2c3030 100%);
}

.with-sidebar {
  display: grid;
  grid-template-columns: 345px 1fr;
  background: inherit;
  overflow: hidden;
}

.explore-sidebar {
  display: grid;
  grid-template-columns: 450px 1fr;
  background: inherit;
  overflow: hidden;
}

@media (max-width: 640px) {
  .with-sidebar,
  .with-sidebar > div {
    max-height: none;
  }
  .with-sidebar {
    grid-template-columns: 100%;
  }
  .left-bar > div {
    padding: 0px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .explore-sidebar > div {
    max-height: none;
    overflow: hidden;
  }
  .explore-sidebar > div[id="collections"] {
    flex: 1;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
  }
  .explore-sidebar {
    display: flex;
    flex-direction: column;
    grid-template-columns: 100%;
    overflow: scroll;
    max-height: none;
  }
}
